const initState = {
    tabIndex: 0

}
const GlobalReducer = (state = initState, action) => {

    switch (action.type) {
        case "SET_TAB_INDEX":
            return {
                ...state,
                tabIndex: action.index,
            }
        default: return state;
    }

}

export default GlobalReducer;