import { React, useEffect, useRef, useState } from "react";
import Cart from "../componants/globals/Cart";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faClose, faList, faPowerOff, faPrint } from '@fortawesome/free-solid-svg-icons'
import ProductInfo from "../componants/cards/ProductInfo";
import InfoMenu from "../componants/cards/MenuInfo";
import { connect, useDispatch, useSelector } from "react-redux";
import { GetSuplements, GetUserCategoriesAction, GetUserMenussAction } from "../store/actions/CategoriesActions";
import CategoryButton from "../componants/btns/CategoryButton";
import { GetSearchAction } from "../store/actions/SearchActions";
import { getParameters, setPrinterParameters } from "../store/actions/ParametersActions";
import Orders from "./Orders";
import PrinterSettings from "../pages/PrinterSettings";
import { setTabIndex } from "../store/actions/GlobalActions";
import { addToCart, resetCart } from "../store/actions/CartActions";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { getShopRooms } from "../store/actions/RoomsActions";
import { GET_APP_SHOP, SignOutAction } from "../store/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import { resetOrderToPrint, setCoupon, setOrderToPrint } from "../store/actions/OrderActions";
import NewProduct from "../componants/modals/NewProduct";
import BarcodeReader from 'react-barcode-reader'
import { TOAST } from "../utils/Helpers";
import { url } from "../config/BaseUrl";

const Home = ({ tabIndex, orderToEdit, _supplements, isWaiter, waiter, cart }) => {

    const dispatch = useDispatch();
    const menus = useSelector(state => state.category.menus);
    const categories = useSelector(state => state.category.categories);
    const itemToEdit = useSelector(state => state.cart.itemToEdit);
    const shop = useSelector(state => state.auth.shops);
    const navigate = useNavigate();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [deliveryMethod, setDeliveryMethod] = useState(null);
    const [selectedAddress, selectAddress] = useState(null);
    const [table, selectTable] = useState(null);
    const [mobile, setMobile] = useState("");
    const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);
    const [nextTabIndex, setNextTabIndex] = useState(null);
    const [isShowCartModal, setShowCartModal] = useState(false);
    const [showPrinterSettings, setShowPrinterSettings] = useState(false);
    const [isShowSellsList, showSellsList] = useState(false);
    const [isShowNewProduct, showNewProduct] = useState(false);
    const [scannedProduct, setScannedProduct] = useState(null);
    const barCodeInputRef = useRef(null);
    const searchRef = useRef(null);
    const packageJson = require("../../package.json");
    const currentVersion = packageJson.version;
    const [scannedBarCode, setScannedBarCode] = useState("")
    const [tvaValue, setTvaValue] = useState("")
    const [isShowPayModal, setShowPayModal] = useState(false)
    const [isShowEditTvaModal, setShowEditTvaModal] = useState(false)
    const [selectedTvaCategory, selectTvaCategory] = useState(false)

    let timeoutId = null;

    useEffect(() => {
    }, [dispatch]);

    useEffect(() => {
        if (!isShowNewProduct && !isShowSellsList && !showPrinterSettings && !showConfirmExitModal && !selectedProduct && !isShowPayModal) {
            barCodeInputRef?.current?.focus()
            //TOAST("Focused", "success")
        } else {
            barCodeInputRef?.current?.blur()
        }
    }, [isShowNewProduct, isShowSellsList, showPrinterSettings, showConfirmExitModal, selectedProduct, isShowPayModal]);

    useEffect(() => {
        checkAuthentication();
        getPrinterParams()
    }, [])

    useEffect(() => {
        if (itemToEdit?.category) {
            setCategory(itemToEdit.category);
        }
    }, [itemToEdit]);

    useEffect(() => {
        if (orderToEdit) {
            switch (orderToEdit.deliveryMethod) {
                case "Sur place": setDeliveryMethod("inplace"); break;
                case "Livraison": setDeliveryMethod("delivery"); break;
                case "À emporter": setDeliveryMethod("pickup"); break;
                default: break;
            }
            selectTable(orderToEdit.table);
            setMobile(orderToEdit.mobile)
            dispatch(setCoupon(orderToEdit.coupon))
            selectAddress({ address: orderToEdit?.address, _mobile: orderToEdit.mobile })
        }
    }, [orderToEdit]);

    const [showpanier, setShowpanier] = useState(true);
    const [selectedMenu, selectMenu] = useState(null);
    const [search, setSearch] = useState("");
    const [selectedCategory, setCategory] = useState(null);

    function openTab(index) {
        if (tabIndex === 0) {
            setShowConfirmExitModal(true);
            setNextTabIndex(index)
            return
        }
        setNextTabIndex(null)
        dispatch(setTabIndex(index))
    }

    function resetAll() {

        setNextTabIndex(null);
        setSelectedProduct(null)
        setMobile("")
        selectTable(null)
        setCategory(null)
        selectMenu(null)
        dispatch(setCoupon(null))
        dispatch(resetCart())
        dispatch(setOrderToPrint(null))
        dispatch(resetOrderToPrint())
        dispatch(setCoupon(null))
        setShowConfirmExitModal(false);
        dispatch(setTabIndex(0))
    }

    const logout = () => {
        dispatch(SignOutAction())
        navigate("/")
    }

    async function checkAuthentication() {

        let token = localStorage.getItem("token")
        if (token) {
            dispatch(getParameters())
            dispatch(GetUserMenussAction());
            dispatch(GetUserCategoriesAction());
            dispatch(GetSuplements());
            dispatch(GetSearchAction());
            dispatch(getShopRooms())
            if (!isWaiter) {
                dispatch(GET_APP_SHOP())
            }
        } else {
            navigate(isWaiter ? "/waiter" : "/")
        }
    }

    async function getPrinterParams() {

        let printerConnexionMode = localStorage.getItem("printerConnexionMode")
        let isUsbMode = printerConnexionMode === "USB"
        let receiptPaperSize = localStorage.getItem("receiptPaperSize")
        let printerEthernetIpAddress = localStorage.getItem("ethernetIpAddress")
        let _printers = localStorage.getItem("printers")
        let copies = localStorage.getItem("copies")
        let margin = localStorage.getItem("margin")
        let isPrintQrCode = localStorage.getItem("isPrintQrCode")
        let _receiptVisibleInfos = localStorage.getItem("receiptVisibleInfos")
        dispatch(setPrinterParameters({
            copies: parseInt(copies) || 1,
            margin: parseInt(margin) || 0,
            isPrintQrCode: isPrintQrCode === 'true',
            receiptPaperSize: parseInt(receiptPaperSize) || 58,
            printerConnexionMode: isUsbMode ? 'USB' : 'ETHERNET',
            printerEthernetIpAddress: printerEthernetIpAddress || "",
            printers: _printers ? JSON.parse(_printers) : [],
            receiptVisibleInfos: (_receiptVisibleInfos && JSON.parse(_receiptVisibleInfos)) || {
                shopName: true,
                shopAddress: true,
                shopMobile: true,
                orderReference: true,
                orderDate: true,
                orderMode: true,
                tableNumber: true,
                paymentMethod: true,
                addressQrCode: true
            }
        }))
    }

    function getProductByBarCode(barCode) {
        let product = null
        let category = null
        categories.forEach(categ => {
            categ.products.forEach(item => {
                if (item.productBarCodeNumber === barCode) {
                    category = categ
                    product = item
                }
            })
        });

        if (!product) {
            // TOAST("Produit introuvable!", "error")
            setScannedBarCode("")
            return
        }

        setSelectedProduct(product)
        setCategory(category)
        setScannedBarCode("")

        if (product?.isProductWithWeight) {
            setScannedProduct(product)
            return
        }

        let categSupplements = _supplements?.filter(item => item.category === category._id)

        if (category?.withSizes === true || (category?.baseChoices?.length > 0 || product?.productBaseChoices?.length > 0 || categSupplements?.length > 0)) {
            setScannedProduct(product)
            return
        }

        let refCartItem = new Date().getTime()
        let item = {
            id: refCartItem,
            category: category,
            price: product.prices[0]?.price,
            size: null,
            quantity: 1,
            product: product,
            ingredients: [],
            baseChoices: [],
            productBaseChoices: [],
            comment: "",
            withSizes: false,
            isMenuItem: false
        }
        console.log("item", item)
        dispatch(addToCart(item))
        setScannedProduct(null)
        setCategory(null)
        setSelectedProduct(null)
    }

    const handleChange = (value) => {

        const newBarcode = value;
        setScannedBarCode(newBarcode);
        clearTimeout(timeoutId);
        getProductByBarCode(newBarcode);
    };

    const handleError = (data) => {
        console.log("data error", data)
    }

    function updateCategoryTva() {

        const headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        url.put("categories/" + selectedTvaCategory?._id, { tva: tvaValue }, { headers: headers })
            .then(response => {
                if (response.status === 200) {
                    TOAST("TVA mis à jour avec succés", "success")
                    setShowEditTvaModal(false)
                    setTvaValue("")
                    selectTvaCategory(null)
                    dispatch(GetUserCategoriesAction());
                }
            })
            .catch(err => { console.log(err) });
    }

    return (

        <>
            {/* 
                <input
                    ref={barCodeInputRef}
                    type="text"
                    autoFocus
                    value={scannedBarCode}
                    onChange={handleChange}
                    id="default-search"
                    className={"absolute top-[-10000px] left-[-10000px]"}
                />
            } */}

            <div className="flex bg-slate-100" style={{ backgroundColor: '#E8E7FB' }}>



                {/* <Sidebar /> */}

                <div className={"flex flex-col w-full 2xl:w-[100%] right-0 absolute"} style={{ transition: "all .2s", backgroundColor: 'white' }}>

                    <div className='w-full flex justify-between items-center h-[65px] px-5' style={{ backgroundColor: '#0F709E' }}
                    // onClick={() => barCodeInputRef?.current?.focus()}
                    >
                        <div className="items-center">
                            <h3 className='text-xl text-white font-bold'>{shop?.name}</h3>
                            <p className='text-md text-white'>V {currentVersion}</p>
                        </div>
                        {!isShowNewProduct && !isShowSellsList && !showPrinterSettings && !showConfirmExitModal && !selectedProduct && !isShowPayModal &&
                            <div>
                                <BarcodeReader
                                    onScan={handleChange}
                                    onError={handleError}
                                    timeBeforeScanTest={500}
                                    avgTimeByChar={50}
                                    minLength={6}
                                />
                            </div>
                        }
                        <div className="items-center">
                            <FontAwesomeIcon onClick={() => showNewProduct(true)} className="" icon={faCirclePlus} style={{ color: "#fff", height: "30px", width: "30px", marginRight: 10 }} />
                            <FontAwesomeIcon onClick={() => showSellsList(true)} className="" icon={faList} style={{ color: "#fff", height: "30px", width: "30px", marginRight: 10 }} />
                            <FontAwesomeIcon onClick={() => setShowPrinterSettings(true)} className="" icon={faPrint} style={{ color: "#fff", height: "30px", width: "30px", marginRight: 10 }} />
                            <FontAwesomeIcon onClick={() => logout()} className="" icon={faPowerOff} style={{ color: "#fff", height: "30px", width: "30px", marginRight: 10 }} />
                        </div>
                    </div>

                    {/* {tabIndex === 0 &&
                    <div className="flex h-full"><Orders /></div>
                } */}

                    {tabIndex === 0 &&
                        <div className="flex h-full">
                            <div className="flex flex-col w-[15%] overflow-y-scroll scrollbar-none h-[calc(100vh-65px)] border-b border-gray-300" style={{ backgroundColor: '#4982B6' }}
                            // onClick={() => barCodeInputRef?.current?.focus()}
                            >
                                <div className="flex-1">
                                    {/* {menus !== '' && (
                                        <CategoryButton
                                            categoryName="Menus"
                                            isActive={(selectedCategory?.name === 'Menus')}
                                            categoryLength={menus.length}
                                            onClick={() => setCategory({ name: 'Menus', products: [] })}
                                        />
                                    )} */}
                                    {categories.map((category, index) => (
                                        <CategoryButton
                                            key={index}
                                            categoryName={category.name}
                                            selectTvaCategory={() => { selectTvaCategory(category); setTvaValue(category?.tva || ""); setShowEditTvaModal(true) }}
                                            isActive={(category.name === selectedCategory?.name)}
                                            categoryLength={category.products.length}
                                            selectCategory={() => {
                                                // barCodeInputRef?.current?.focus();
                                                setCategory(category);
                                            }}
                                        />
                                    ))}
                                </div>
                                {/* <button onClick={() => resetAll(1)} type="button" className={"h-[70px] w-[100%] px-5 bottom-0 flex items-center justify-center gap-4 text-[20px] text-white"} style={{ color: '#fff', backgroundColor: '#FF0036' }} data-te-ripple-init>
                                <FontAwesomeIcon className="" icon={faRefresh} style={{ color: "#fff", height: "25px", width: "25px" }} />
                                Réinitialiser
                            </button> */}
                            </div>

                            <div className="w-full w-[60%]"
                            // onClick={() => barCodeInputRef?.current?.focus()}
                            >
                                {/* <div className="relative flex h-[45px] bg-white items-center border-l border-gray-300" style={{ backgroundColor: '#EBEBED' }}>
                                <input
                                    ref={searchRef}
                                    type="text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    id="default-search"
                                    className={"block px-6 py-2 text-md text-gray-900 border-0 w-[100%]"}
                                    placeholder={"Chercher ..."}
                                    required
                                    disabled={selectedCategory === null}
                                    onBlur={(e) => {
                                        barCodeInputRef?.current?.focus()
                                    }}
                                    style={{ color: '#8E8D92', backgroundColor: '#EBEBED', opacity: selectedCategory ? 1 : 0.4 }}
                                />
                                {search !== "" &&
                                    <FontAwesomeIcon
                                        icon={faClose}
                                        style={{ height: "30px", width: "30px" }}
                                        onClick={() => setSearch("")}
                                        className="px-5"
                                    />
                                }
                            </div> */}
                                <div className="overflow-y-scroll overflow-x-hidden scrollbar-none w-[71%]" style={{ backgroundColor: 'white' }}>
                                    {selectedCategory?.name === "Menus"
                                        ? <div className="grid grid-cols-4">
                                            {menus.filter((menu) => menu.status === "enabled" && menu.name.toLowerCase().includes(search.toLowerCase())).map((menu) =>
                                                <InfoMenu
                                                    setCategory={() => setCategory(null)}
                                                    category={selectedCategory}
                                                    menu={menu}
                                                    selectMenu={(value) => selectMenu(value)}
                                                    selectedMenu={selectedMenu}
                                                />
                                            )}
                                        </div>
                                        : categories.filter((category) => category.name === selectedCategory?.name).map((item, i) => {
                                            return (
                                                <div className="grid grid-cols-4">
                                                    {item.products.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())).map((product) => {
                                                        return (
                                                            <ProductInfo
                                                                selectedProduct={selectedProduct}
                                                                setSelectedProduct={setSelectedProduct}
                                                                setCategory={() => setCategory(null)}
                                                                category={selectedCategory}
                                                                product={product}
                                                                name={item.name}
                                                                baseChoices={item.baseChoices}
                                                                productBaseChoices={product.baseChoices}
                                                                withSizes={item.withSizes}
                                                                scannedProduct={scannedProduct}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {/* 
                {tabIndex === 2 &&
                    <div className="flex h-full"><Products /></div>
                }

                {tabIndex === 3 &&
                    <div className="flex h-full"><Rooms /></div>
                }

                {tabIndex === 4 &&
                    <div className="flex h-full"><Clients /></div>
                }

                {tabIndex === 5 &&
                    <div className="flex h-full"><Waiters /></div>
                }

                {tabIndex === 6 &&
                    <div className="flex h-full"><PrinterSettings /></div>
                }

                {tabIndex === 7 &&
                    <div className="flex h-full"><Settings /></div>
                } */}

                </div>

                {/* {tabIndex === 1 &&
                <button onClick={() => resetAll()} type="button" className={"h-[50px] fixed bottom-[100px] flex items-center justify-center gap-4 text-[20px] text-white " + (tabIndex === 1 ? "2xl:w-[75%] sm:w-[70%]" : "2xl:w-[100%]")} style={{ color: tabIndex === 0 ? '#1B557D' : '#fff', backgroundColor: '#FF0036' }} data-te-ripple-init>
                    <FontAwesomeIcon className="" icon={faRefresh} style={{ color: tabIndex === 0 ? "#1B557D" : "#fff", height: "25px", width: "25px" }} />
                    Réinitialiser la commande
                </button>
            } */}

                {/* <button onClick={() => { openTab(0); }} type="button" className="h-full flex flex-col items-center justify-center gap-2 px-10 text-md bg-black text-white" style={{ backgroundColor: tabIndex === 0 ? '#fff' : '#1B557D', color: tabIndex === 0 ? '#1B557D' : '#fff' }} data-te-ripple-init>
                <FontAwesomeIcon className="" icon={faBellConcierge} style={{ color: tabIndex === 0 ? "#1B557D" : "#fff", height: "30px", width: "30px" }} />
                Ventes
            </button> */}
                {/* 
            <div className={"w-full h-[100px] grid " + (isWaiter ? "lg:grid-cols-4" : "lg:grid-cols-8") + " fixed bottom-0 right-0 " + (tabIndex === 1 && !waiter ? "2xl:w-[75%] sm:w-[70%]" : "2xl:w-[100%]")} style={{ backgroundColor: '#1B557D' }}>
                <button onClick={() => { openTab(0); }} type="button" className="h-full flex flex-col items-center justify-center gap-2 px-10 text-md bg-black text-white" style={{ backgroundColor: tabIndex === 0 ? '#fff' : '#1B557D', color: tabIndex === 0 ? '#1B557D' : '#fff' }} data-te-ripple-init>
                    <FontAwesomeIcon className="" icon={faBellConcierge} style={{ color: tabIndex === 0 ? "#1B557D" : "#fff", height: "30px", width: "30px" }} />
                    Ventes
                </button>
                <button onClick={() => { openTab(1); }} type="button" className="h-full flex flex-col items-center justify-center gap-2 px-10 text-md bg-black text-white" style={{ backgroundColor: tabIndex === 1 ? '#fff' : '#1B557D', color: tabIndex === 1 ? '#1B557D' : '#fff' }} data-te-ripple-init>
                    <FontAwesomeIcon className="" icon={faPlus} style={{ color: tabIndex === 1 ? "#1B557D" : "#fff", height: "30px", width: "30px" }} />
                    Commande
                </button>
                {!isWaiter &&
                    <button onClick={() => { openTab(2);; dispatch(GetUserMenussAction()); dispatch(GetUserCategoriesAction()) }} type="button" className="h-full flex flex-col items-center justify-center gap-2 px-10 text-md bg-black text-white" style={{ backgroundColor: tabIndex === 2 ? '#fff' : '#1B557D', color: tabIndex === 2 ? '#1B557D' : '#fff' }} data-te-ripple-init>
                        <FontAwesomeIcon className="" icon={faBurger} style={{ color: tabIndex === 2 ? "#1B557D" : "#fff", height: "30px", width: "30px" }} />
                        Produits
                    </button>
                }
                <button onClick={() => { openTab(3);; dispatch(getShopRooms()) }} type="button" className="h-full flex flex-col items-center justify-center gap-2 px-10 text-md bg-black text-white" style={{ backgroundColor: tabIndex === 3 ? '#fff' : '#1B557D', color: tabIndex === 3 ? '#1B557D' : '#fff' }} data-te-ripple-init>
                    <FontAwesomeIcon className="" icon={faUtensils} style={{ color: tabIndex === 3 ? "#1B557D" : "#fff", height: "30px", width: "30px" }} />
                    Salles
                </button>
                {!isWaiter &&
                    <button onClick={() => { openTab(4); }} type="button" className="h-full flex flex-col items-center justify-center gap-2 px-10 text-md bg-black text-white" style={{ backgroundColor: tabIndex === 4 ? '#fff' : '#1B557D', color: tabIndex === 4 ? '#1B557D' : '#fff' }} data-te-ripple-init>
                        <FontAwesomeIcon className="" icon={faPeopleGroup} style={{ color: tabIndex === 4 ? "#1B557D" : "#fff", height: "30px", width: "30px" }} />
                        Clients
                    </button>
                }
                {!isWaiter &&
                    <button onClick={() => { openTab(5); }} type="button" className="h-full flex flex-col items-center justify-center gap-2 px-10 text-md bg-black text-white" style={{ backgroundColor: tabIndex === 5 ? '#fff' : '#1B557D', color: tabIndex === 5 ? '#1B557D' : '#fff' }} data-te-ripple-init>
                        <FontAwesomeIcon className="" icon={faUserTie} style={{ color: tabIndex === 5 ? "#1B557D" : "#fff", height: "30px", width: "30px" }} />
                        Serveurs
                    </button>
                }
                <button onClick={() => { openTab(6); }} type="button" className="h-full flex flex-col items-center justify-center gap-2 px-10 text-md bg-black text-white" style={{ backgroundColor: tabIndex === 6 ? '#fff' : '#1B557D', color: tabIndex === 6 ? '#1B557D' : '#fff' }} data-te-ripple-init>
                    <FontAwesomeIcon className="" icon={faPrint} style={{ color: tabIndex === 6 ? "#1B557D" : "#fff", height: "30px", width: "30px" }} />
                    Imprimante
                </button>
                {!isWaiter &&
                    <button onClick={() => { openTab(7); }} type="button" className="h-full flex flex-col items-center justify-center gap-2 px-10 text-md bg-black text-white" style={{ backgroundColor: tabIndex === 7 ? '#fff' : '#1B557D', color: tabIndex === 7 ? '#1B557D' : '#fff' }} data-te-ripple-init>
                        <FontAwesomeIcon className="" icon={faGear} style={{ color: tabIndex === 7 ? "#1B557D" : "#fff", height: "30px", width: "30px" }} />
                        Paramètres
                    </button>
                }
            </div> */}
                <div style={{
                    transition: "all .2s",
                    visibility: "visible",
                }}
                // onClick={() => barCodeInputRef?.current?.focus()}
                >
                    <Cart
                        showpanier={showpanier}
                        setShowpanier={setShowpanier}
                        deliveryMethod={deliveryMethod}
                        resetDeliveryMetthod={() => setDeliveryMethod(null)}
                        selectedAddress={selectedAddress}
                        mobile={mobile}
                        table={table}
                        resetTable={() => selectTable(null)}
                        resetAllStates={() => resetAll()}
                        hideModal={() => setShowCartModal(false)}
                        isShowCartModal={isShowCartModal}
                        setShowPayModal={(value) => setShowPayModal(value)}
                        // updateBarCodeFocus={(isFocus) => isFocus ? barCodeInputRef?.current?.focus() : barCodeInputRef?.current?.blur()}
                        updateBarCodeFocus={(isFocus) => console.log("")}
                    />
                    {/* {cart.length &&
                    <p style={{ height: 30, width: 30, textAlign: 'center', fontSize: 16, paddingTop: 5, fontWeight: 'bold', borderRadius: 15, backgroundColor: 'red', color: 'white', position: 'absolute', bottom: 55, zIndex: 1, right: 15 }}>{cart.length}</p>
                }
                <button
                    onClick={() => (setShowCartModal(true))}
                    className="bg-blue-600 flex items-center justify-center rounded-full h-16 w-16 fixed bottom-[10px] right-[15px]" style={{ backgroundColor: '#009B77' }}>
                    <FontAwesomeIcon icon={faCartPlus} style={{ color: "#fff", height: "30px", width: "30px" }} />
                </button> */}
                </div>

                <Dialog
                    open={showConfirmExitModal}
                    maxWidth='md'
                    onClose={() => showConfirmExitModal(false)}
                    className="modalWrapper">
                    <DialogTitle>
                        <p className="text-[25px]">Êtes vous sûr vouloir quitter sans enregistrer la commande?</p>
                    </DialogTitle>
                    <DialogActions>
                        <button
                            className="bg-black flex gap-3 w-full items-center justify-center text-white font-bold uppercase text-xl py-5"
                            onClick={() => setShowConfirmExitModal(false)}>
                            Rester
                        </button>
                        <button
                            className="bg-primary flex gap-3 w-full items-center justify-center text-white font-medium uppercase text-xl py-5"
                            onClick={() => resetAll()}>
                            Quitter
                        </button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={showPrinterSettings}
                    maxWidth='xl'
                    onClose={() => setShowPrinterSettings(false)}
                    className="modalWrapper">
                    <DialogTitle>
                        <div className="flex justify-between items-center">
                            <p className="text-[25px] font-bold">Réglages d'impression</p>
                            <FontAwesomeIcon onClick={() => setShowPrinterSettings(false)} className="" icon={faClose} style={{ color: "#000", height: "30px", width: "30px", marginRight: 10 }} />
                        </div>
                    </DialogTitle>
                    <PrinterSettings hideModal={() => setShowPrinterSettings(false)} />
                </Dialog>


                <Dialog
                    open={isShowSellsList}
                    maxWidth='xl'
                    onClose={() => showSellsList(false)}
                    PaperProps={{
                        style: {
                            minWidth: '95%',
                            minHeight: '95%'
                        }
                    }}
                    className="modalWrapper">
                    <DialogTitle>
                        <div className="flex justify-between items-center">
                            <p className="text-[25px] font-bold">Liste des ventes</p>
                            <FontAwesomeIcon onClick={() => showSellsList(false)} className="" icon={faClose} style={{ color: "#000", height: "30px", width: "30px", marginRight: 10 }} />
                        </div>
                    </DialogTitle>
                    <Orders />
                </Dialog>

                <Dialog
                    open={isShowEditTvaModal}
                    PaperProps={{
                        style: {
                            minWidth: '20%',
                        }
                    }}
                    onClose={() => { setShowEditTvaModal(false); setTvaValue(""); selectedTvaCategory(null) }}
                    className="modalWrapper">
                    <DialogTitle>
                        <div className="flex justify-between items-center">
                            <p className="text-[25px] font-bold">{selectedTvaCategory?.name} - TVA {selectedTvaCategory?.tva ? (selectedTvaCategory?.tva + "%") : ""}</p>
                            <FontAwesomeIcon
                                onClick={() => { setShowEditTvaModal(false); setTvaValue(""); selectedTvaCategory(null) }}
                                className=""
                                icon={faClose} style={{ color: "#000", height: "30px", width: "30px", marginRight: 10 }} />
                        </div>
                    </DialogTitle>
                    <div className='flex flex-row px-6 align-center justify-center mt-3'>
                        <p className="text-[20px] pt-2" style={{ flex: 0.5 }}>TVA %</p>
                        <input
                            style={{ flex: 1 }}
                            type="number"
                            className="form-control text-[20px] h-12 border border-gray-700  px-4 text-md bg-white"
                            placeholder="..."
                            value={tvaValue}
                            onChange={(e) => { setTvaValue(e.target.value) }}
                        />
                    </div>
                    <DialogActions>
                        <button
                            className="bg-black flex gap-3 w-full items-center justify-center text-white font-bold uppercase text-xl py-5"
                            onClick={() => updateCategoryTva()}>
                            Mettre à jour
                        </button>
                        <button
                            className="bg-primary flex gap-3 w-full items-center justify-center text-white font-medium uppercase text-xl py-5"
                            onClick={() => { setShowEditTvaModal(false); setTvaValue(""); selectedTvaCategory(null); }}>
                            Quitter
                        </button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={isShowNewProduct}
                    onClose={() => showNewProduct(false)}
                    PaperProps={{
                        style: {
                            minWidth: '50%',
                        }
                    }}
                    className="modalWrapper">
                    <DialogTitle>
                        <div className="flex justify-between items-center">
                            <p className="text-[25px] font-bold">Nouveau produit</p>
                            <FontAwesomeIcon onClick={() => showNewProduct(false)} className="" icon={faClose} style={{ color: "#000", height: "30px", width: "30px", marginRight: 10 }} />
                        </div>
                    </DialogTitle>
                    <NewProduct hideModal={() => showNewProduct(false)} />
                </Dialog>
            </div>
        </>
    )
}

function mapStateToProps(state) {
    return {
        tabIndex: state.global.tabIndex,
        orderToEdit: state.order.orderToEdit,
        isWaiter: state.auth.isWaiter,
        waiter: state.auth.waiter,
        cart: state.cart.items,
        _supplements: state.category.supplements
    }
}

export default connect(mapStateToProps)(Home);

const styles = {

    printerButton: {
        width: 70,
        height: 70,
        borderRadius: 50,
        backgroundColor: 'white',
        position: 'absolute',
        bottom: 10,
        right: 20
    }
}