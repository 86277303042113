import { React, useEffect, useState, createRef } from 'react'
import QRCode from 'qrcode'
import './style.css';
import logo from '../../assets/images/logo2.png'
import { useScreenshot } from 'use-react-screenshot';
import { useSelector } from 'react-redux';
import { TOAST } from '../../utils/Helpers';

const TestReceipt = ({ receiptVisibleInfos, printerConnexionMode, printers, margin, paperSize }) => {

    useEffect(() => {
        generateQrCodeUri()
    }, [])

    const [qrcodeUri, setQrCodeUri] = useState("")
    const [image, takeScreenShot] = useScreenshot();
    const ref = createRef(null)
    const shop = useSelector(state => state.auth.shops);

    const generateQrCodeUri = async () => {
        try {
            let uri = await QRCode.toDataURL('http://maps.apple.com/maps?daddr=50.465021495628534,4.855736621158314')
            setQrCodeUri(uri)
        } catch (err) { }
    }

    async function printTestReceipt() {

        let isUsbPort = printerConnexionMode === "USB"

        if (isUsbPort) {
            let imagedata = await takeScreenShot(ref.current)
            let usbData = (imagedata + "#" + 1)
            window.location.href = "#print-test-receipt#" + usbData
        } else {
            if (printers.length === 0) {
                TOAST("Veuillez ajouter une imprimante", "error")
                return
            }
            printers.map(async (printer) => {
                if (printer.ipAddress === "") {
                    TOAST("Veuillez ajouter l'adresse ip de " + printer.name, "error")
                    return;
                }
                let imagedata = await takeScreenShot(ref.current)
                let ethernetData = (imagedata + "#" + 1 + "#" + printer.ipAddress)
                window.location.href = "#print-test-receipt#" + ethernetData + "#" + ref.current.offsetHeight
            })
        }
    }

    if(printerConnexionMode === "USB"){
        return (
            <>
                <div className='flex flex-col w-full items-center' style={{ backgroundColor: 'white', paddingRight: margin, width: paperSize === 58 ? 390 : 570 }} ref={ref}>
                    <p className='text-[45px] font-black' style={{ fontWeight: 'bold', color: 'black'}}>{shop?.name}</p>
                    <p className='text-[30px] mt-1 font-bold' style={{ fontWeight: 'bold', color: 'black'}}>Rue de la paix, Namur, 5000</p>
                    <p className='text-[30px] font-bold' style={{ fontWeight: 'bold', color: 'black'}}>Tél: 0657952301</p>
                    {/* {receiptVisibleInfos?.orderReference && <p className='mt-4   text-[35px]'>#78563493</p>} */}
                    <p className='mt-3 text-[27px] font-bold' style={{ fontWeight: 'bold', color: 'black'}}>02 Mercredi 2023 23:05</p>
                    <div className='w-[95%] border-2 border-gray-100 my-3' />
                    <p className='text-[30px] w-full px-5 font-bold' style={{ fontWeight: 'bold', color: 'black'}}>Pizza - Extra Large</p>
                    <div className='flex mt-1 justify-between px-8 w-full'>
                        <p className='text-[27px]  font-bold' style={{ fontWeight: 'bold', color: 'black'}}>2x &nbsp;Margherita</p>
                        <p className='text-[27px]  font-bold' style={{ fontWeight: 'bold', color: 'black'}}>€23</p>
                    </div>
                    <p className='text-[25px] mt-1 px-12 w-full font-bold' style={{ fontWeight: 'bold', color: 'black'}}>+ 2x Mozarella</p>
                    <p className='text-[25px] px-12 w-full font-bold' style={{ fontWeight: 'bold', color: 'black'}}>+ Tomate</p>
                    <div className='flex mt-6 justify-between px-8 w-full'>
                        <p className='text-[27px]  font-bold' style={{ fontWeight: 'bold', color: 'black'}}>Sous total</p>
                        <p className='text-[27px]  font-bold' style={{ fontWeight: 'bold', color: 'black'}}>€23</p>
                    </div>
                    <div className='flex mt-1 justify-between px-8 w-full'>
                        <p className='text-[27px]  flex-1 font-bold' style={{ fontWeight: 'bold', color: 'black'}}>Coupon (5%)</p>
                        <p className='text-[27px]  font-bold' style={{ fontWeight: 'bold', color: 'black'}}>-€1.15</p>
                    </div>
                    <div className='flex mt-1 justify-between px-8 w-full'>
                        <p className='text-[27px] font-bold' style={{ fontWeight: 'bold', color: 'black'}}>Frais de livraison</p>
                        <p className='text-[27px]  font-bold' style={{ fontWeight: 'bold', color: 'black'}}>Gratuit</p>
                    </div>
                    <div className='flex mt-3 justify-between px-8 w-full'>
                        <p className='text-[30px]   font-bold' style={{ fontWeight: 'bold', color: 'black'}}>TOTAL</p>
                        <p className='text-[30px]   font-bold' style={{ fontWeight: 'bold', color: 'black'}}>€18</p>
                    </div>
                    <div className='w-[95%] border-2 border-gray-100 my-3' />
                    {/* <img src={qrcodeUri} height={200} width={200} alt="" /> */}
                    <div className='flex mt-5'>
                        <img alt="" src={logo} height={25} width={25} style={{ objectFit: 'contain' }} />
                        <p className='text-[27px] ml-3 font-bold' style={{ fontWeight: 'bold', color: 'black'}}>Powerd by B-TIP</p>
                    </div>
                </div>
                <button className="bg-black text-white mt-20   text-xl h-[55px]" style={{ width: paperSize === 58 ? 390 : 570 }} onClick={() => printTestReceipt()}>
                    {"Imprimer reçu de test".toUpperCase()}
                </button>
            </>
        )
    }

    return (
        <>
            <div className='flex flex-col w-full py-3 items-center' style={{ backgroundColor: 'white', paddingRight: margin, width: paperSize === 58 ? 390 : 570 }} ref={ref}>
                <p className='text-[40px] font-bold'>{shop?.name}</p>
                <p className='text-[30px] mt-1'>Rue de la paix, Namur, 5000</p>
                <p className='text-[30px]'>Tél: 0657952301</p>
                {/* {receiptVisibleInfos?.orderReference && <p className='mt-4   text-[35px]'>#78563493</p>} */}
                <p className='text-[25px] mt-3'>02 Mercredi 2023 23:05</p>
                <div className='w-[95%] border-2 border-gray-100 my-3' />
                <p className='text-[30px]   w-full px-5'>Pizza - Extra Large</p>
                <div className='flex mt-1 justify-between px-8 w-full'>
                    <p className='text-[27px] font-medium'>2x &nbsp;Margherita</p>
                    <p className='text-[27px] font-medium'>€23</p>
                </div>
                <p className='text-[25px] mt-1 px-12 w-full'>+ 2x Mozarella</p>
                <p className='text-[25px] px-12 w-full'>+ Tomate</p>
                <div className='flex mt-6 justify-between px-8 w-full'>
                    <p className='text-[25px] font-medium'>Sous total</p>
                    <p className='text-[25px] font-medium'>€23</p>
                </div>
                <div className='flex mt-1 justify-between px-8 w-full'>
                    <p className='text-[25px] font-medium flex-1'>Coupon (5%)</p>
                    <p className='text-[25px] font-medium'>-€1.15</p>
                </div>
                <div className='flex mt-1 justify-between px-8 w-full'>
                    <p className='text-[25px] font-medium'>Frais de livraison</p>
                    <p className='text-[25px] font-medium'>Gratuit</p>
                </div>
                <div className='flex mt-3 justify-between px-8 w-full'>
                    <p className='text-[30px] font-semibold'>TOTAL</p>
                    <p className='text-[30px] font-semibold'>€18</p>
                </div>
                <div className='w-[95%] border-2 border-gray-100 my-3' />
                {/* <img src={qrcodeUri} height={200} width={200} alt="" /> */}
                <div className='flex my-5'>
                    <img alt="" src={logo} height={25} width={25} style={{ objectFit: 'contain' }} />
                    <p className='text-[27px] font-normal ml-3'>Powerd by B-TIP</p>
                </div>
            </div>
            <button className="bg-black text-white mt-20   text-xl h-[55px]" style={{ width: paperSize === 58 ? 390 : 570 }} onClick={() => printTestReceipt()}>
                {"Imprimer reçu de test".toUpperCase()}
            </button>
        </>
    )
}

export default TestReceipt;




