import { Routes, Route } from "react-router-dom"
import Login from "./pages/Login"
import Home from "./pages/Home"
import './App.css';
import './Font.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
import SelectWaiter from "./pages/SelectWaiter";

const App = () => {

  return (
    <div className="App" style={{ overflow: 'hidden'}}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/waiter" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/select-waiter" element={<SelectWaiter />} />
      </Routes>
      <ToastContainer />
    </div>
  )
}

export default App