const initState = {
    token: localStorage.getItem("token"),
    shops: {
        status: null,
        name: null,
        image: null,
        _id: null,
        latitude: null,
        longitude: null,
        googlePlaceID: null,
    },
    email: null,
    isWaiter: false,
    status: null,
    role: { _id: null, name: null },
    isLoggedIn: false,
    waiter: null
}

const AuthReducer = (state = initState, action) => {

    switch (action.type) {
        case "SET_IS_WAITER":
            return { ...state, isWaiter: action.isWaiter };
        case "SET_WAITER":
            return { ...state, waiter: action.waiter };
        case "SIGN_IN":
            return {
                ...state,
                token: localStorage.getItem("token"),
                shops: {
                    status: action.shops[0].status,
                    name: action.shops[0].name,
                    image: action.shops[0].image,
                    _id: action.shops[0]._id,
                    latitude: action.shops[0].latitude,
                    longitude: action.shops[0].longitude,
                    googlePlaceID: action.shops[0].googlePlaceID,
                },
                email: action.email,
                status: action.status,
                role: {
                    _id: action.role._id,
                    name: action.role.name
                },
                isLoggedIn: action.isLoggedIn,
            };
        case "GET_INFO":
            return {
                ...state,
                shops: {
                    name: action.shops.name,
                    _id: action.shops._id,
                    latitude: action.shops.latitude,
                    longitude: action.shops.longitude,
                    googlePlaceID: action.shops.googlePlaceID,
                    vatNumber: action.shops.vatNumber
                }
            }
        case "SIGN_OUT":
            localStorage.removeItem("token");
            return {
                ...state,
                isLoggedIn: false,
                shops: {
                    status: null,
                    name: null,
                    image: null,
                    _id: null,
                    latitude: null,
                    longitude: null,
                    googlePlaceID: null,
                },
                email: null,
                status: null,
                role: {
                    _id: null,
                    name: null
                }
            }
        default: return state;
    }
}

export default AuthReducer;
