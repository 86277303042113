import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCircleXmark, faPenToSquare, faTag } from '@fortawesome/free-solid-svg-icons'
import { faComment, faTrash, faClose } from '@fortawesome/free-solid-svg-icons'
import { increaseItemQuantity, decreaseItemQuantity, updateCartItemPrice, setItemToEdit } from '../../store/actions/CartActions'
import { useDispatch } from 'react-redux'
import { GET_CART_ITEM_TOTAL, TOAST } from '../../utils/Helpers'

const CartItem = ({
    _item,
    handleSelectItem,
    removeCartItem,
    updateBarCodeFocus
}) => {

    useEffect(() => {
        setItem(_item)
    }, [_item])

    useEffect(() => {
        setOldPrice(GET_CART_ITEM_TOTAL(item).toFixed(1))
    }, [])

    const [item, setItem] = useState(_item)
    const [isShowComment, setShowComment] = useState(false)
    const [isPercent, setPercent] = useState(null)
    const [discountValue, setDiscountValue] = useState("")
    const [isApplyDiscount, setApplyDiscount] = useState(false)
    const [discountInfo, setDiscountInfo] = useState("")
    const [oldPrice, setOldPrice] = useState(0)
    const deductionRef = useRef(null);

    const dispatch = useDispatch();

    function addDiscountToProductPrice() {

        if (discountValue === 0 || discountValue === "") {
            TOAST("Veuillez saisir le montant de réduction", "error")
            return
          }

        let newPrice = oldPrice - (isPercent ? (oldPrice * parseFloat(discountValue) * 0.01) : parseFloat(discountValue))
        dispatch(updateCartItemPrice({ id: item.id, price: newPrice <= 0 ? 0 : newPrice }))
        setApplyDiscount(false)
        setDiscountInfo(isPercent ? ("Réduction " + discountValue + "%") : ("Réduction -€" + discountValue))
    }

    function resetStates() {

        setDiscountValue("")
        setPercent(null)
        setDiscountInfo("")
    }

    const handleInputChange = (event) => {
        event.stopPropagation(); // Stop event from bubbling up to parent
        updateBarCodeFocus(false); 
    };


    return (
        <div className='bg-white px-2 pt-3 mt-1 pb-2 shadow-md' style={{ border: '0px solid', borderWidth: 1 }}>
            <div className='flex justify-between'>
                <div className='flex flex-row flex-1 gap-3'>
                    <span className='text-base text-sm font-semibold'>{item.quantity}</span>
                    <h4 className='text-base text-sm font-semibold whitespace-nowrap'>
                        {((item.menu?.name) || (item.product?.name))}<span className='font-normal text-sm'>{item.size && (" - " + (item.size?.name || item.size) + (item.product?.isProductWithWeight ? " Kg" : ""))}</span></h4>
                </div>
                <span className='flex flex-row flex-1 font-bold text-sm text-primary justify-end'>{ }€{GET_CART_ITEM_TOTAL(item).toFixed(1)}</span>
            </div>
            <div className='flex justify-between items-center'>
                <div className='flex gap-2 items-center'>
                    {discountInfo === "" && <span className='text-sm border text-center' style={{ textDecorationLine: 'underline', color: '#000' }} onClick={() => setApplyDiscount(true)}>Ajouter une réduction</span>}
                    {discountInfo !== "" &&
                        <div className='flex gap-1 items-center'>
                            <p className='text-gray-500 text-sm' style={{ textDecorationLine: 'underline', fontStyle: 'italic', color: 'black' }}>{discountInfo}</p>
                            <FontAwesomeIcon icon={faCircleXmark} onClick={() => { dispatch(updateCartItemPrice({ id: item.id, price: oldPrice })); resetStates() }} style={{ color: "black", height: 16, width: 16 }} />
                        </div>
                    }
                </div>
                <div className='flex flex-row justify-center gap-2 items-center'>
                    <button
                        onClick={() => item.quantity === 1 ? removeCartItem(item.id) : dispatch(decreaseItemQuantity(item.id))}
                        className='h-6 w-6 leading-3 pb-0.2 bg-none text-sm text-dark border text-center cursor-pointer'>
                        -
                    </button>
                    <span className='font-bold text-sm h-6 w-6 border text-center'>{item.quantity}</span>
                    <button
                        onClick={() => dispatch(increaseItemQuantity(item.id))}
                        className='h-6 w-6 pb-0.5 leading-3 text-sm text-dark border text-center cursor-pointer'>
                        +
                    </button>
                    <FontAwesomeIcon icon={faTrash} onClick={() => removeCartItem(item.id)} style={{ color: "red", height: 16, width: 16 }} />
                </div>

            </div>
            {isApplyDiscount &&
                <div className='flex justify-between border border-gray-500 m-1 mt-2 mb-3 p-2 shadow-lg'>
                    <div className="w-full">
                        <div className='flex items-center gap-2'>
                            <input
                                type="checkbox"
                                className='h-[24px] w-[24px]'
                                checked={isPercent === true}
                                onChange={(e) => { setPercent(true); deductionRef?.current?.focus(); }}
                            />
                            <span className='font-medium text-lg text-center'>%</span>
                            <input
                                type="checkbox"
                                className='h-[24px] w-[24px]'
                                checked={isPercent === false}
                                onChange={(e) => { setPercent(false); deductionRef?.current?.focus(); }}
                            />
                            <span className='font-medium text-lg text-center'>€</span>
                        </div>

                        <input
                            ref={deductionRef}
                            type="number"
                            style={{ pointerEvents: isPercent === null ? 'none' : null, opacity: isPercent === null ? 0.3 : 1 }}
                            className="form-control h-10 w-full mt-2 border border-gray-700 px-4 text-md bg-white"
                            placeholder="..."
                            onClick={handleInputChange}
                            min={0}
                            onBlur={() => {
                                updateBarCodeFocus(true)
                            }}
                            value={discountValue}
                            onChange={(e) => setDiscountValue(e.target.value)}
                        />
                        <div className='flex w-full justify-end'>
                            <button
                                onClick={() => addDiscountToProductPrice()}
                                className='flex items-center gap-2 py-1 rounded mt-1 text-sm' style={{ color: '#0F709E', fontWeight: '600' }}>
                                <p>Appliquer</p>
                            </button>
                            <button
                                onClick={() => { setApplyDiscount(false); resetStates() }}
                                className='flex items-center gap-2 py-1 px-2 rounded mt-1 text-sm'>
                                <p>Fermer</p>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default CartItem